<template>
    <div class="home-slider container">
        <div class="row">
            <div class="col-12 col-lg-6">
                <h1>
                    <!--
                    Codziennie świeże pieczywo
                    <br />
                    bez wychodzenia z domu
                    -->
                    Pierwsza dostawa gratis
                    <br/>
                    Sprawdź jak to działa
                </h1>
                <router-link outlined :to="{name: 'OrderAdd'}" class="btn m-md-2 pl-4 pr-4 btn-order btn-shadow">zamawiam on-line</router-link>
                <div class="phone-order-info">
                    zamawiam telefonicznie
                    <br />
                    <a href="tel:+48333332885">(+48) 333 332 885</a>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <img src="@/assets/slider/banner-chleb.png" class="baner mt-4"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "homeSlider",
  props: {
  }
};
</script>