<template>
  <div class="home-page">
        <homeSlider />
    
        <div class="creamBg home-delivery-doors py-5 mt-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="text-center p-2">
                            <h2>Budzisz się a pieczywo już czeka pod drzwiami</h2>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="text-center p-2">
                            <h2>Wypieki tylko z lokalnej piekarni</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <homeAvailability />

        <div class="creamBg">
            <div class="container ">
                <div class="row py-5">
                    <div class="col-md-3 col-12 text-center">
                        <div class="homeCircleIcon" >
                            <img src="@/assets/icon-chleb.svg" class="homeIconBread" />
                        </div>
                        <p class="text-center pb-0 pt-3">wybierz pieczywo</p>
                    </div>
                    <div class="col-md-1 col-12 text-center pt-5 pb-5">
                        <img src="@/assets/icon-arrow.svg" class="sm-rotate90" />
                    </div>

                    <div class="col-md-3 col-12">
                        <div class="homeCircleIcon" >
                            <img src="@/assets/icon-calendar.svg" class="homeIconCallendar" />
                        </div>
                        <p class="text-center pb-0 pt-3">
                            wybierz dni dostawy
                        </p>
                    </div>

                    <div class="col-md-1 col-12 text-center pt-5 pb-5">
                        <img src="@/assets/icon-arrow.svg" class="sm-rotate90" />
                    </div>

                    <div class="col-md-3 col-12">
                        <div class="homeCircleIcon" >
                            <img src="@/assets/icon-option.svg" class="homeIconOption" />
                        </div>
                        <p class="text-center pb-0 pt-3">
                            jednorazowa dostawa lub abonament
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <homeProducts />

        <homeSubscriptions />

        <div class="container py-5">
            <div class="text-center">
                <img src="@/assets/logo.svg" alt="Logo" class="pb-4">
                <div class="h3">
                    <br />
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" /> 
                    <br />
                    al. Armii Krajowej 220
                    <br />
                    43-300 Bielsko-Biała                    
                </div>
                <div class="h4">
                    <br />
                    <br />
                    tel.: <a href="tel:+48333332885">(+48) 333 332 885</a>
                    <br />
                    <a href="mailto:zamawiam@chlebpoddom.pl" class="h4">zamawiam@chlebpoddom.pl</a>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import homeSlider from "@/components/home/Slider.vue";
import homeAvailability from "@/components/home/Availability.vue";
import homeSubscriptions from "@/components/home/Subscriptions.vue";
import homeProducts from "@/components/home/Products.vue";

export default {
    name: "Home",
    components: {
        homeSlider,
        homeSubscriptions,
        homeProducts,
        homeAvailability
    },
    
    data: () => ({
        
    }),
};
</script>
