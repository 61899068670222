<template>
    <div class="home-availability">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="text-center p-2">
                        <h3>Bezpieczne dostawy pieczywa do twojego domu.</h3>
                        <p>Sprawdź dostępność w twojej miejscowości.</p>

                        <form method="post" @submit.prevent="checkAvailability" action="" class="form-md-inline">
                            <inputText v-model="availability_code" name="code" label="kod pocztowy" :error="validation.firstError('availability_code')"/>
                            <button type="submit" class="btn btn-primary" >Sprawdź</button>
                        </form>

                        <div v-if="availabilityMsg" :class="msgStyle">{{ availabilityMsg }}</div>
                        <form v-if="availabilityStatus==2" method="post" @submit.prevent="notifyAvailability" action="" class="form-md-inline">
                            <div class="m-4">Wypełnij formularz aby otrzymać powiadomienie o dostępności w tej miejscowości.</div>
                            <div v-if="availabilityStatus==2">
                                <inputText v-model="availability_name" name="name" label="Imię" :error="validation.firstError('availability_name')"/>
                                <inputText v-model="availability_email" name="email" label="e-mail" :error="validation.firstError('availability_email')"/>
                                <inputText v-model="availability_phone" name="phone" label="Telefon" :error="validation.firstError('availability_phone')"/>
                            </div>
                            <inputText v-model="availability_code" name="code" label="kod pocztowy" :error="validation.firstError('availability_code')"/>
                            <button v-if="availabilityStatus" type="submit" class="btn btn-success" >Powiadom mnie</button>
                        </form>
                        <div v-if="availabilityNotifyConfirmation" class="m-5">{{ availabilityNotifyConfirmationMsg }}</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;
export default {
    name: "homeAvalaibility",
    data() {
        return {
            loading: true,
            availability_code: '',
            availabilityStatus: null,
            availabilityMsg: '',
            availabilityNotifyConfirmation: null,
            availability_name: null,
            availability_email: null,
            availability_phone: null
        }
    },
    validators: {
        'availability_name': function (value) {
            return Validator.value(value).required().minLength(3);
        },
        'availability_email': function (value) {
            return Validator.value(value).required().email();
        },
        'availability_phone': function (value) {
            return Validator.value(value).required().minLength(9);
        },
        'availability_code': function (value) {
            return Validator.value(value).required().regex('^[0-9]{2}-[0-9]{3}$', 'Podaj poprawny kod pocztowy');
        },
    },
    methods: {        
        checkAvailability() {
            this.$validate('availability_code').then( (success) => {
                if (success) {

                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
                    axios.post('/availability', {
                        "availability": this.availability_code
                    }).then((result) => {
                        this.availabilityStatus = result.data.status;
                        this.availabilityMsg = result.data.message;
                        this.availabilityNotifyConfirmation = null;
                        this.availabilityNotifyConfirmationMsg = null;
                    }).catch( () => {
                        this.isLoading = false;
                    });

                }
            });            
        }, 
        notifyAvailability() {
            this.$validate().then( (success) => {
                if (success) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
                    axios.post('/availability_notify', {
                        "availability": this.availability_code,
                        "availability_name": this.availability_name,
                        "availability_email": this.availability_email,
                        "availability_phone": this.availability_phone
                    }).then((result) => {
                        this.availabilityStatus = null;
                        this.availabilityMsg = null;
                        this.availability_code = '';
                        this.availabilityNotifyConfirmation = true;
                        this.availabilityNotifyConfirmationMsg = result.data.message;
                    }).catch( () => {
                        this.isLoading = false;
                        this.$bvToast.toast(`Błąd pobierania danych`, {
                            title: 'Błąd',
                            autoHideDelay: 5000,
                            solid: true,
                            variant: 'danger',
                            appendToast: true,
                            toaster: 'b-toaster-top-right'
                        })
                    })
                } else {
                    this.$bvToast.toast(`Wypełnij dokładnie formularz.`, {
                        title: 'Uwaga',
                        autoHideDelay: 1000,
                        solid: true,
                        variant: 'warning',
                        appendToast: true,
                        toaster: 'b-toaster-top-right'
                    })
                }
            });
        }
    },
    computed: {
        msgStyle(){
            if(this.availabilityStatus==1)
                return 'text-success m-5';
            else if (this.availabilityStatus==2) 
                return 'text-danger m-5';
            else 
                return '';
        }
    }
};
</script>