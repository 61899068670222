<template>
    <div class="container">
        <div class="text-center py-5">
            <img src="@/assets/klos.svg" />
            <h2>PAKIETY DOSTAW</h2>
        </div>
        
        <div class="row justify-content-md-center mb-5">
            <div class="col-12 col-lg-8 ">
                <b-card-group deck>
                    <b-card header-tag="header">
                        <template #header>
                            <h3 class="text-center mb-0">systematyczne dostawy</h3>
                        </template>
                        <b-card-text>
                            <ul>
                                       <li>dostawy od 3zł</li>
                                       <li>bezpieczna płatność</li>                                       
                                       <li>bezpieczne dostawy bez kontaktu z kurierem</li>
                                       <li>sam wybierasz dni dostaw</li>
                                       <li>świeże pieczywo bez wychodzenia z domu</li>
                                       <li>dostawy przed 7:00</li>
                                       <li>płacisz raz, dostawy otrzymujesz w wybrane dni tygodnia</li>
                                       <li>sam wybierasz harmonogram dostaw</li>
                                       <li>oszczędzasz czas i pieniądze</li>
                                       <li>intuicyjny system zamówień</li>
                                       <li>dostęp do historii zamówień</li>
                                       
                            </ul>
                        </b-card-text>
                        <center>
                            <b-button variant="secondary" @click.prevent="setScheduleSubscription()" >Wybieram</b-button>
                        </center>
                    </b-card>
                    <b-card header-tag="header">
                        <template #header>
                            <h3 class="text-center mb-0">jednorazowa dostawa</h3>
                        </template>
                        <b-card-text>
                            <ul>
                                <li>bezpieczna płatność</li>
                                <li>świeże pieczywo bez wychodzenia z domu</li>
                                <li>dostawa przed 7:30</li>
                                <li>możliwość przetestowania naszych usług</li>
                                <li>dostawa 7,50 zł</li>
                            </ul>
                        </b-card-text>
                        <center>
                            <b-button variant="secondary" @click.prevent="setScheduleOneTime()" >Wybieram</b-button>
                        </center>
                    </b-card>
                </b-card-group>
            </div>
            
        </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";
import {SCHEDULE_typeSubscription, SCHEDULE_typeOneTimePamyent} from "@/constants/constants.js";
export default {
    name: "homeSubscriptions",
    props: {
    },
    data: () => ({
        scheduleSubscriptionLink : {name: 'OrderAdd', params: { scheduleType: SCHEDULE_typeSubscription}},
        scheduleOneTimeLink: {name: 'OrderAdd', params: { scheduleType: SCHEDULE_typeOneTimePamyent }}
    }),
    computed: {
        ...mapGetters({isLoggedIn: 'auth/loggedIn', me : 'auth/me' }),
    },
    methods: {
        setScheduleSubscription() {
            if(!this.isLoggedIn) {
                this.$router.push('/login') 
            }
            this.$store.dispatch('order/updateScheduleType', SCHEDULE_typeSubscription)
                .then( () => {
                    this.$router.push( {name: 'OrderAdd'} );
                })
            .catch(err => {
                console.log(err)
            });
        },
        setScheduleOneTime() {
            if(!this.isLoggedIn) {
                this.$router.push('/login') 
            }
            this.$store.dispatch('order/updateScheduleType', SCHEDULE_typeOneTimePamyent)
                .then( () => {
                    this.$router.push( {name: 'OrderAdd'} );
                })
            .catch(err => {
                console.log(err)
            });
        }
    }
};
</script>
