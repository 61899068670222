<template>
    <div class="offWhiteBg">
        <div class="container ">
            <div class="text-center pt-5">
                <img src="@/assets/klos.svg" />
                <h2>PRODUKTY</h2>
            </div>

            <div class="row text-center mt-5 pb-5">
                <div class="col-12 col-md-4 mb-4">
                    <router-link :to="{name: 'Products'}">
                        <img src="@/assets/produkty/grupa-chleb.png" />
                        <h3 class="mt-3">CHLEBY</h3>
                        <button class="btn btn-secondary mb-4">Zobacz</button>
                    </router-link>
                </div>
                <div class="col-12 col-md-4 mb-4">
                    <router-link :to="{name: 'Products'}">
                        <img src="@/assets/produkty/grupa-bulki.png" />
                        <h3 class="mt-3">BUŁKI</h3>
                        <button class="btn btn-secondary mb-4">Zobacz</button>
                    </router-link>
                </div>
                <div class="col-12 col-md-4 mb-4">
                    <router-link :to="{name: 'Products'}">
                        <img src="@/assets/produkty/grupa-slodkie.png" />
                        <h3 class="mt-3">SŁODKIE WYPIEKI</h3>
                        <button class="btn btn-secondary mb-4">Zobacz</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "homeProducts",
    props: {
    }
};
</script>
